<template>
  <v-container
    fill-height
    fluid
    grid-list-xl
  >
    <v-layout
      justify-center
      wrap
    >
      <v-flex md12>
        <form>
          <!-- General Info -->
          <material-card
            :title="$t('userProfile.userProfile')"
            color="green"
            flat
            full-width
            class="bg-white"
          >
            <!-- Box Body -->
            <v-form>
              <v-container py-0>
                <v-layout wrap>
                  <!-- Full Name -->
                  <v-flex
                    xs12
                    md4
                  >
                    <v-text-field
                      v-model="userProfile.fullName"
                      :label="$t('user.fullname')"
                      class="purple-input"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    md4
                  >
                    <v-text-field
                      v-model="userProfile.email"
                      :disabled="userProfile.id != 0 && !isNull(userProfile.id)"
                      :label="$t('user.email')"
                      class="purple-input"
                    />
                  </v-flex>
                  <!-- Phone -->
                  <v-flex
                    xs12
                    md4
                  >
                    <v-text-field
                      v-model="userProfile.phone"
                      :label="$t('user.phone')"
                      class="purple-input"
                    />
                  </v-flex>
                  <!-- Gender -->
                  <v-flex
                    xs12
                    md4
                  >
                    <v-select
                      v-model="userProfile.gender"
                      :items="userProfile.genderList"
                      :label="$t('userProfile.gender')"
                      item-text="text"
                      item-value="value"
                    >
                      <template
                        slot="selection"
                        slot-scope="data">
                        {{ $t(data.item.text) }}
                      </template>
                      <template
                        slot="item"
                        slot-scope="data">
                        {{ $t(data.item.text) }}
                      </template>
                    </v-select>
                  </v-flex>
                  <!-- Date of birth -->
                  <v-flex
                    xs12
                    md4
                  >
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="userProfile.birthDateFormatted"
                          :label="$t('user.dob')"
                          persistent-hint
                          @blur="date = parseDate(userProfile.birthDateFormatted)"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="birthDate"
                        no-title
                        @input="menu = false"/>
                    </v-menu>
                  </v-flex>
                  <!-- Identification -->
                  <v-flex
                    v-if="forConasi"
                    xs12
                    md4
                  >
                    <v-text-field
                      v-model="userProfile.identification"
                      :label="$t('users.identification')"
                      class="purple-input"
                    />
                  </v-flex>
                  <!-- System role -->
                  <v-flex
                    v-if="isAdmin()"
                    xs12
                    md4
                  >
                    <v-select
                      v-model="userProfile.sysRole"
                      :items="systemRoleList"
                      :label="$t('decentralizationGroup.privilege')"
                      disabled
                    >
                      <template
                        slot="selection"
                        slot-scope="data">
                        {{ $t(data.item.text) }}
                      </template>
                      <template
                        slot="item"
                        slot-scope="data">
                        {{ $t(data.item.text) }}
                      </template>
                    </v-select>
                  </v-flex>
                  <!-- Entity Role -->
                  <v-flex
                    v-if="!isAdmin()"
                    xs12
                    md4
                  >
                    <v-select
                      v-model="userProfile.entityRole"
                      :items="entityRoleList"
                      :label="$t('users.assignedRoles')"
                      disabled
                    >
                      <template
                        slot="selection"
                        slot-scope="data">
                        {{ $t(data.item.text) }}
                      </template>
                      <template
                        slot="item"
                        slot-scope="data">
                        {{ $t(data.item.text) }}
                      </template>
                    </v-select>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
            <!-- /.Box Body -->
            <!-- Box Footer -->
            <v-flex
              xs12
              md12
            >
              <v-btn
                class="white--text"
                color="red"
                style="margin-right: 5px;"
                @click="onClose()"
              >
                <span class="btn-label">
                  <i
                    class="glyphicon glyphicon-remove"
                    style="margin-right: 5px;"/>
                </span>
                {{ $t('common.close') }}
              </v-btn>
              <v-btn
                class="ma-2"
                color="warning"
                style="margin-right: 5px;"
                @click="onLogOut()"
              >
                <span class="btn-label">
                  <i
                    class="glyphicon glyphicon-log-out"
                    style="margin-right: 5px;"/>
                </span>
                {{ $t('common.logOut') }}
              </v-btn>
              <v-btn
                class="ma-2"
                color="success"
                style="margin-right: 5px;"
                @click="saveUserProfile()"
              >
                <span class="btn-label">
                  <i
                    class="glyphicon glyphicon-ok"
                    style="margin-right: 5px;"/>
                </span>
                {{ $t('common.save') }}
              </v-btn>
              <button
                v-if="googleSocialNetworkData && ENABLE_BINDING_GOOGLE"
                type="button"
                class="btn btn-google v-btn"
                disabled
              >
                <i class="fa fa-google mr-2"/> {{ ellipsisLongText(googleSocialNetworkData.full_name, 10) }}
              </button>
              <google-button-component
                v-if="googleSocialNetworkData === null && ENABLE_BINDING_GOOGLE"
                :data-width="147"
                :data-text="'signup_with'"
                @onEmitData="googleBtnEmitData"
              />
              <button
                v-if="facebookSocialNetworkData && ENABLE_BINDING_FACEBOOK"
                type="button"
                class="btn btn-facebook v-btn"
                disabled
              >
                <i class="fa fa-facebook mr-2"/> {{ ellipsisLongText(facebookSocialNetworkData.full_name, 10) }}
              </button>
              <button
                v-if="facebookSocialNetworkData === null && ENABLE_BINDING_FACEBOOK"
                type="button"
                class="btn btn-facebook v-btn ml-2"
                @click="postLoginWithFacebook(isConfirmAddSocialNetwork=false)">
                <i class="fa fa-facebook mr-2"/> {{ $t('common.facebookAccountLink') }}
              </button>
            </v-flex>
          </material-card>
        </form>
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar" />
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="confirmAddSocialNetwork"
    />
  </v-container>
</template>
<script>
import { FOR_CONASI_TARGET } from 'utils/constants'
import stringUtils from 'utils/stringUtils'
import functionUtils from 'utils/functionUtils'
import dateUtils from 'utils/dateUtils'
import ToastType from 'enum/toastType'
import EntityRoleType from 'enum/entityRoleType'
import { mapActions, mapGetters } from 'vuex'
import { isCookieEnabled, getCookie } from 'tiny-cookie'
import SocialNetworkType from 'enum/socialNetworkType'
import { initFbsdk } from '../../config/facebook_oAuth.js'
import ConfirmModal from 'Components/ConfirmModal'
import GoogleButtonComponent from 'Components/GoogleButtonComponent'
export default {
  components: {
    ConfirmModal,
    GoogleButtonComponent
  },
  data: () => ({
    menu: false,
    userProfile: {
      id: null,
      birthDateFormatted: null,
      gender: null,
      phone: null,
      fullName: null,
      email: null,
      genderList: stringUtils.GenderList,
      sysRole: null,
      entityRole: null,
      identification: null
    },
    systemRoleList: stringUtils.SystemRoleList,
    entityRoleList: stringUtils.EntityRoleList,
    birthDate: null,
    googleSocialNetworkData: null,
    facebookSocialNetworkData: null,
    ENABLE_BINDING_GOOGLE: process.env.VUE_APP_ENABLE_BINDING_GOOGLE.trim() === 'true',
    ENABLE_BINDING_FACEBOOK: process.env.VUE_APP_ENABLE_BINDING_FACEBOOK.trim() === 'true',
    confirmModalTitle: '',
    socialNetworkTypeSelect: null,
    socialNetworkSelect: null,
    isConfirmAddSocialNetwork: false,
    forConasi: FOR_CONASI_TARGET
  }),
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.birthDate)
    },
    ...mapGetters([
      'GET_CURRENT_ACCOUNT',
      'GET_SNACK_BAR'
    ])
  },
  watch: {
    birthDate (val) {
      this.userProfile.birthDateFormatted = this.formatDate(this.birthDate)
    },
    GET_CURRENT_ACCOUNT () {
      let data = this.GET_CURRENT_ACCOUNT
      this.userProfile.email = data.email
      this.userProfile.fullName = functionUtils.concatFullname(data.lastname, data.firstname)
      this.userProfile.gender = data.gender
      this.userProfile.phone = data.phone
      this.userProfile.birthDateFormatted = dateUtils.formatBeautyDate(data.birth_date, null)
      this.userProfile.identification = data.identification
      this.userProfile.sysRole = data.sys_role
      this.userProfile.entityRole = EntityRoleType.STAFF
      if (!functionUtils.isNull(data.entity_role)) {
        // data.entity_role.name
        this.userProfile.entityRole = data.entity_role.type
      }
    }
  },
  mounted () {
    initFbsdk()
  },
  created () {
    let userId = this.$route.params.id
    this.userProfile.id = userId
    if (!functionUtils.isNull(userId) && userId !== 0) {
      this.GET_USER_DATA()
    }
    this.getSocialNetwork()
    initFbsdk()
  },
  methods: {
    /**
     * Check null
     */
    ellipsisLongText: function (value, len) {
      return functionUtils.ellipsisLongText(value, len)
    },
    /**
     * Check null
     */
    isNull: function (data) {
      return functionUtils.isNull(data)
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    roleInEntityName: function (role) {
      return functionUtils.roleInEntityName(role)
    },
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Save user profile
     */
    saveUserProfile: function () {
      let firstLastName = functionUtils.getFirstLastName(this.userProfile.fullName)
      let filter = {
        lastName: firstLastName.lastName,
        firstName: firstLastName.firstName,
        birthDate: this.userProfile.birthDateFormatted,
        phone: this.userProfile.phone,
        gender: this.userProfile.gender,
        identification: this.userProfile.identification
      }
      this.UPDATE_CURRENT_USER({ id: this.userProfile.id, ...filter }).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.GET_USER_DATA()
        }.bind(this)
      ).catch(
        function (error) {
          let errorMessage = error.response.data.message
          if (!functionUtils.isNull(errorMessage) && errorMessage.includes('(identification)')) {
            this.ON_SHOW_TOAST({
              'message': this.$t('users.errorText.indentAlreadyExist'),
              styletype: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              'message': this.$t('login.somethingWentWrong'),
              styletype: ToastType.ERROR
            })
          }
        }.bind(this)
      )
    },
    /**
     * Remove entity session storage
     */
    removeEntitySessionStorage: function () {
      sessionStorage.removeItem('entityId')
      sessionStorage.removeItem('entityName')
      sessionStorage.removeItem('entityType')
      sessionStorage.removeItem('entityImage')
      sessionStorage.removeItem('entityPhone')
      sessionStorage.removeItem('entityWebsite')
      sessionStorage.removeItem('roleInEntity')
    },
    /**
     * Log out
     */
    onLogOut: function () {
      let token = isCookieEnabled() ? getCookie('token') : null
      this.LOGOUT(token).then(
        function () {
          this.removeEntitySessionStorage()
          this.$router.push({
            name: 'login'
          })
        }.bind(this)
      ).catch(
        function () {
          this.removeEntitySessionStorage()
          this.$router.push({
            name: 'login'
          })
        }.bind(this)
      )
    },
    /**
     * Close
     */
    onClose: function () {
      window.history.back()
    },
    onClickLoginGoogle: function () {
      this.$gAuth
        .signIn()
        .then(GoogleUser => {
          // on success do something
          var profile = GoogleUser.getBasicProfile()
          if (Object.keys(profile).length > 0) {
            let userProfile = {
              id: profile.getId(),
              fullName: profile.getName(),
              avatarUrl: profile.getImageUrl(),
              email: profile.getEmail()
            }
            this.postGoogleLogin(userProfile)
            this.socialNetworkSelect = userProfile
          }
        })
    },
    postGoogleLogin: function (userInfo) {
      let googleSocialNetwork = SocialNetworkType.GOOGLE
      this.socialNetworkTypeSelect = googleSocialNetwork
      let data = {
        socialNetworkType: googleSocialNetwork,
        fullName: userInfo && userInfo.fullName,
        socialId: userInfo && userInfo.id,
        email: userInfo && userInfo.email,
        avatarUrl: userInfo && userInfo.avatarUrl,
        isConfirmAddSocialNetwork: this.isConfirmAddSocialNetwork
      }
      this.SOCIAL_NETWORK_LOGIN(data).then(
        function () {
          this.getSocialNetwork()
        }.bind(this)
      ).catch(
        function (error) {
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              'message': this.$t(`${errorText}`),
              styletype: ToastType.ERROR
            })
          } else if (!functionUtils.isNull(error.response.data.isShowModalConfirm)) {
            this.onShowModalConfirmAddSocialNetwork()
          }
        }.bind(this)
      )
    },
    // facebook
    postLoginWithFacebook () {
      window.FB.login(response => {
        if (response && response.authResponse) {
          window.FB.api(`/${response.authResponse.userID}`, userResponse => {
            if (userResponse) {
              this.postFacebookLogin(userResponse)
              this.socialNetworkSelect = userResponse
            }
          }, { scope: 'email' })
        }
      }, { scope: 'email' })
    },
    postFacebookLogin: function (userInfo) {
      let facebookSocialNetwork = SocialNetworkType.FACEBOOK
      this.socialNetworkTypeSelect = facebookSocialNetwork
      let data = {
        socialNetworkType: facebookSocialNetwork,
        fullName: userInfo && userInfo.name,
        socialId: userInfo && userInfo.id,
        email: null,
        avatarUrl: null,
        isConfirmAddSocialNetwork: this.isConfirmAddSocialNetwork
      }
      this.SOCIAL_NETWORK_LOGIN(data).then(
        function () {
          this.getSocialNetwork()
        }.bind(this)
      ).catch(
        function (error) {
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              'message': this.$t(`${errorText}`),
              styletype: ToastType.ERROR
            })
          } else if (!functionUtils.isNull(error.response.data.isShowModalConfirm)) {
            this.onShowModalConfirmAddSocialNetwork()
          }
        }.bind(this)
      )
    },
    getSocialNetwork: function () {
      this.GET_SOCIAL_NETWORK().then(
        function (res) {
          let dataList = res.data
          let googleSocialNetworkIndex = dataList.findIndex(x => x.social_type === SocialNetworkType.GOOGLE)
          this.googleSocialNetworkData = googleSocialNetworkIndex !== -1 ? dataList[googleSocialNetworkIndex] : null
          let facebookSocialNetworkIndex = dataList.findIndex(x => x.social_type === SocialNetworkType.FACEBOOK)
          this.facebookSocialNetworkData = facebookSocialNetworkIndex !== -1 ? dataList[facebookSocialNetworkIndex] : null
        }.bind(this)
      ).catch(
        function () {}
      )
    },
    confirmAddSocialNetwork: function () {
      this.isConfirmAddSocialNetwork = true
      if (this.socialNetworkTypeSelect === SocialNetworkType.GOOGLE) {
        this.postGoogleLogin(this.socialNetworkSelect)
      } else if (this.socialNetworkTypeSelect === SocialNetworkType.FACEBOOK) {
        this.postFacebookLogin(this.socialNetworkSelect)
      }
    },
    onShowModalConfirmAddSocialNetwork: function () {
      this.$refs.confirmModal.onShowModal()
      this.confirmModalTitle = this.$t('socialNetwork.notiAddSocialAccountLink',
        { '0': this.socialNetworkTypeSelect === SocialNetworkType.GOOGLE ? 'google' : 'facebook' })
    },
    googleBtnEmitData: function (data) {
      this.postGoogleLogin(data)
      this.socialNetworkSelect = data
    },
    ...mapActions([
      'GET_USER_DATA',
      'LOGOUT',
      'UPDATE_CURRENT_USER',
      'ON_SHOW_TOAST',
      'SOCIAL_NETWORK_LOGIN',
      'GET_SOCIAL_NETWORK'
    ])
  }
}
</script>
